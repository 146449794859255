<template>
    <nav>
        <ul class="pagination ">
            <li @click.prevent="clickPage(1)"><a class="page-link">
                    Pierwsza
                </a></li>
            <li class="page-item " :class="{ active: pagination.page == index }" @click.prevent="clickPage(index)"
                v-for="index, k in pagination.pages" :key="k">
                <a class="page-link" href="#"
                    v-if="pagination.page != index && ((index > Number(pagination.page) - 5 && index <= Number(pagination.page)) || (index < Number(pagination.page) + 5 && index >= Number(pagination.page)))">
                    {{ index }}
                </a>
                <span v-if="pagination.page == index" class="page-link">
                    {{ index }}
                </span>
            </li>
            <li @click.prevent="clickPage(pagination.pages)"><a class="page-link">
                    Ostatnia
                </a></li>
        </ul>
    </nav>
</template>
<script>
export default {
    props: ['value'],

    created: function () {
        this.pagination = this.value;
    },
    watch: {
        value: function () {
            this.pagination = this.value;
        }
    },
    methods: {
        clickPage: function (page) {
            this.pagination.page = page;
            this.$emit('change', this.pagination);
            this.$emit('input', this.pagination);
        }
    },
    data: function () {
        return {
            items: [],
            pagination: {},
            modal: false,
        }
    },
}
</script>