<template>
    <div> <span
            :class="'timeline-point timeline-point-' + ['primary', 'danger', 'succes', 'info', 'secondary'][k % 5]"></span>
        <div class="timeline-event">
            <div class="timeline-header mb-1 ">

                <h6 class="mb-0">
                    {{ item.log_data.title }}
                </h6>
                <small class="text-muted" :title="item.created_at">{{
                    dayjs().to(dayjs(item.created_at)) }}</small>
            </div>
            <div class="timeline-body border-bottom pb-3 mb-3">

                <a :href="item.log_data.file_href"><i class="bx bx-link-alt me-1"></i> {{ item.log_data.file_name }}</a>
            </div>
            <div class="d-flex align-items-center">

                <MagicoAvatar :size="38" :user_id="item.user_id" class="me-2"></MagicoAvatar>
                {{ getUserName(item.user_id) }}
            </div>
        </div>
    </div>
</template>
<script>
import MagicoAvatar from '../../../components/MagicoAvatar.vue';
import dayjs from 'dayjs';

export default {
    props: ['item','k'],
    components: {
        MagicoAvatar
    },
    data: function () {
        return {
            dayjs: dayjs,
        }
    },
    methods: {
        getUserName(user_id) {
            let user = this.$store.state.users.find(item => user_id == item.id);
            return user?.name ? user.name : user?.username;
        },
    }
}
</script>