<template id="task-template">
    <div class="container-xxl flex-grow-1 container-p-y app-kanban">

        <div class="row kanban-wrapper">
            <div class="col-12 col-md-12 mb-4">
                <div class="card kanban-wrapper">
                    <div class="card-header d-flex justify-content-between flex-wrap mb-0 pb-1 ">
                        <div>
                            <small>PROJEKT</small>
                            <h5 style="min-height: 20px;">
                                {{ project.project_shortname }}
                            </h5>
                        </div>
                        <div>
                            <router-link :to="'/project/' + id + '/edit'" class="btn btn-outline-primary me-2 f-cap">
                                Edytuj projekt
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

            <ProjectNavComponent :project_id="id" value="summary"></ProjectNavComponent>

            <div class="col-12 mb-3">
                <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <h5 class="card-title m-0 me-2">Ostatnia aktywność w projekcie</h5>
                        <div class="dropdown">
                            <button class="btn p-0" type="button" id="timelineWapper" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-dots-vertical-rounded"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="timelineWapper">
                                <a @click.prevent="getProjectLogs()" class="dropdown-item"
                                    href="javascript:void(0);">Odswież</a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Activity Timeline -->
                        <ul class="timeline">
                            <li v-for="item, k in items" :key="k" class="timeline-item timeline-item-transparent">
                                <component v-if="item.log_data.component" :is="item.log_data.component" :item="item" :k="k">
                                </component>
                                <div v-else>
                                    <span
                                        :class="'timeline-point timeline-point-' + ['primary', 'danger', 'succes', 'info', 'secondary'][k % 5]"></span>
                                    <div class="timeline-event">
                                        <div class="timeline-header mb-1 ">

                                            <h6 class="mb-0" v-html="item.message"></h6>
                                            <small class="text-muted" :title="item.created_at">{{
                                                dayjs().to(dayjs(item.created_at)) }}</small>
                                        </div>
                                        <div class="timeline-body border-bottom pb-3 mb-3">
                                            jaki opis tego co tu sie dzieje
                                        </div>
                                        <div class="d-flex align-items-center">

                                            <MagicoAvatar :size="38" :user_id="item.user_id" class="me-2"></MagicoAvatar>
                                            {{ getUserName(item.user_id) }}
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="timeline-end-indicator">
                                <i class="bx bx-check-circle"></i>
                            </li>
                        </ul>
                        <!-- /Activity Timeline -->
                        <div class="d-flex justify-content-center">
                            <Pagination @change="getProjectLogs" v-if="pagination.pages > 1" v-model="pagination">
                            </Pagination>
                        </div>
                    </div>

                </div>
            </div>

            <!-- <div class="col-md-2">
                <div class="card">
                    <div class="card-header">
                        <h5>Tablice</h5>
                    </div>
                    <div class="card-body p-1">
                        <ul class="list-group">
                            <li class="list-group-item" v-for="item, index in project.boards" :key="index">{{
                                item.board_name }}
                                <router-link :to="'/board/' + item.board_id">Show</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import axios from '../../api/axios';
import api from '../../api/api';
import dayjs from 'dayjs';
import 'dayjs/locale/pl' // ES 2015 
import ProjectNavComponent from './components/ProjectNavComponent.vue';
import MagicoAvatar from '../../components/MagicoAvatar.vue';
import Pagination from '../../components/Pagination.vue'

import BoardCommentLog from './logs/BoardCommentLog.vue';
import FileManagerUploadLog from './logs/FileManagerUploadLog.vue';
import FileManagerDeleteLog from './logs/FileManagerDeleteLog.vue';
import ProjectChangeLog from './logs/ProjectChangeLog.vue';
import TaskCreateLog from './logs/TaskCreateLog.vue';

export default {
    name: 'task',
    template: '#task-template',
    components: {
        ProjectNavComponent,
        MagicoAvatar,
        Pagination,
        BoardCommentLog,
        FileManagerUploadLog,
        FileManagerDeleteLog,
        ProjectChangeLog,
        TaskCreateLog,

    },
    data: function () {
        return {
            dayjs: dayjs,
            BASE_URL: api.getBaseUrl(),
            slownik_user: this.$store.state.slownik_user,
            slownik_project_settlements: this.$store.state.slownik_project_settlements,
            slownik_project_contract: this.$store.state.slownik_project_contract,
            slownik_user_position: this.$store.state.slownik_user_position,

            g_slownik_contract: [],
            g_slownik_project_settlements: [],
            isLoading: true,
            id: 0,
            allItems: [],
            items: [],
            client: {},
            pagination: {
                pages: 3,
                page: 1
            },
            project: {
                client_id: 0,
                project_name: '',
                project_created_at: '',
                project_status: '',
                project_settlements: '',
                project_contract: '',
                boards: [],
            },
            search: {
                page: 1,
                limit: 100,
                pages: 1,
                task_status: '',
                task_name: '',
                task_priority: '',
                task_post_id: '',
                task_module: 'project',
            },

            files: [],
            links: [],
        }
    },
    computed: {},
    methods: {
        lang(x) { return x },
        postionFromSlownik(user_position) {
            this.slownik_user_position.forEach(a => {
                user_position = a.value == user_position ? a.name : user_position;
            })
            return user_position;
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },


        getClient: function () {
            let vm = this;
            axios.get(this.BASE_URL + 'api/v1/client/' + this.project.client_id).then(response => {
                vm.client = response.data;
            });
        },
        getProjectLogs: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/project/' + this.id + '/log?limit=25&offset=' + ((this.pagination.page - 1) * 25)).then(response => {
                //stworzenie pustych walidacji
                vm.isLoading = false;
                vm.pagination.page = (response.data.offset / 25) + 1
                vm.pagination.pages = Math.ceil(response.data.count_all / 25)
                vm.items = response.data.data;

            });
        },

        getProject: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/project/' + this.id + '').then(response => {
                //stworzenie pustych walidacji
                vm.isLoading = false;

                vm.project = response.data;

            });
        },
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar || '/userprofile.png';
        },
        getUserName(user_id) {
            let user = this.$store.state.users.find(item => user_id == item.id);
            return user?.name ? user.name : user?.username;
        },
        clickPage(page) {
            this.search.page = page;
            this.getPosts();
        },
        onDelete() {
            this.getPosts();
        },
        clickShow: function (item) {
            this.$router.push('/task/' + item.project_id);
        },
        clickEdit: function (item) {
            this.$router.push('/task/' + this.id + '/edit/' + item.task_id);
        },
        getPosts: function () {

            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/task', {
                params: this.search
            }).then(response => {
                vm.allItems = response.data.data;
                vm.search.pages = response.data.pages;
                vm.isLoading = false;
                // vm.loadFiles();
            });
        },
        submitSearchForm: function () {
            this.search.page = 1;
            this.getPosts();
        },
        resetSearchForm: function () {
            for (const property in this.search) {
                this.search[property] = '';
            }
            this.getPosts();
        },
        loadFiles: function () {
            console.log(this._links.files);
            axios.get(this._links.files.href).then(response => {
                this.files = response.data.data;
                console.log(this.files);
            })
        },
    },
    created() {
        // this.search.task_group = this.$route.params.slownik ? this.$route.params.slownik : '';
        this.id = this.$route.params.id;
        this.search.task_post_id = this.id;
        this.getProjectLogs();
        this.getProject();
        var relativeTime = require('dayjs/plugin/relativeTime')
        dayjs.extend(relativeTime);
        dayjs.locale('pl') // use locale globally

    }
}
</script>
<style>
.kanban-board-header .kanban-title-board {
    font-size: 1.125rem;
    max-width: 13rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
}

.kanban-title-board::first-letter {
    text-transform: capitalize;
}
</style>